<template>
  <keep-alive>
    <component
      :is="current"
      v-bind="params"
      @edit="onEdit"
      @new="onNew"
      @close="backToList"
    />
  </keep-alive>
</template>

<script>
import toolList from '@/views/dashboard/components/tools/toolList';
import toolForm from '@/views/dashboard/components/tools/toolForm';

export default {
  components: {},
  data: function () {
    return {
      current: null,
      params: {}
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    this.current = toolList;
    this.params = {};
  },
  methods: {
    onNew() {
      this.current = toolForm;
      this.params = {
        value: {
          id: '',
          name: '',
          type: '',
          status: '',
          client: '',
          cost: 0,
          when_created: new Date(),
          when_ordered: new Date(),
          when_delivered: new Date(),
          supplier: '',
          dimension_x: 0,
          dimension_y: 0,
          dimension_z: 0,
          nesting: 1,
          location: '',
          params: []
        }
      };
    },
    onEdit(item) {
      this.current = toolForm;
      this.params = {
        value: item
      };
    },
    backToList() {
      this.current = toolList;
      this.params = {};
    }
  }
};
</script>

<style></style>
